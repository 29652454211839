// Packages
import React from 'react';

// Components
import Layout from '../components/Layout';
import PageHeader from '../components/reusables/PageHeader';
import ContactContent from '../components/contact/ContactContent';
import HeadMetadata from '../components/HeadMetadata';

export default function Contact() {
    return (
        <Layout>
            <HeadMetadata title="Contact"></HeadMetadata>
            <PageHeader
                image="kingdom-tournament.jpg"
                title="CONTACT US"
                text=""
            ></PageHeader>
            <ContactContent></ContactContent>
        </Layout>
    );
}