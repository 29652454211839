// Packages
import React, { useState } from "react";
import emailjs from 'emailjs-com';

// Components

export default function ContactContent() {

    const [sent, setSent] = useState(false);

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_1ywbx24', 'template_xjw3r6c', e.target, 'user_gNdNRQ3gWcwlKQg8i6CHE')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
        setSent(true);
    }
    
    return (
        <div className="contact-content content-wrapper">
            <div className="contact-page-texts">
                <h2>INTERESTED IN ONE OF OUR CLASSES?</h2>
                <p>Send us with any inquiries or call (780) 484-6524. <br/> We would be more than happy to answer your questions!</p>
            </div>
            <div className="contact-form-wrapper" id="contactform">
                <form className={`contact-form ${ sent ? 'remove-form' : ''}`} onSubmit={e => sendEmail(e)}>
                    <div className="row name-row">
                        <input id="name" type="text" name="name" required placeholder="your name"></input>
                    </div>
                    <div className="row email-row">
                        <input id="email" type="email" name="email" required placeholder="your email"></input>
                    </div>
                    <div className="row phone-row">
                        <input id="telephone" type="tel" name="telephone" required placeholder="your phone (optional)"></input>
                    </div>
                    <div className="row message-row">
                        <textarea id="message" name="message" rows={5} placeholder="your message" required></textarea>
                    </div>
                    <div className="row submit-row">
                        <input type="submit" value="SEND MESSAGE" className="send-button"></input>
                    </div>
                </form>
                <div className={`form-submitted-message ${ sent ? 'message-sent' : ''}`}>
                    <h2>Thanks for the message! We'll contact you as soon as we can!</h2>
                    <p onClick={() => setSent(false)} className="new-message-button">NEW MESSAGE</p>
                </div>
            </div>
        </div>
    );
}